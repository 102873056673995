import { PaginationParams } from '@/models/api'
import { defineStore } from 'pinia'
import { ElNotification } from 'element-plus'

import * as tripsApi from '@/api/trips'
import * as reviewsApi from '@/api/reviews'

import {
  FinishTripPaymentPayload,
  TripExtra,
  TripFull,
  TripFullWithRelations,
} from '@/models/trip'
import { DamageReportStatus } from '@/models/damageReport'

import { parseErrors } from '@/helpers/error-parsers'

import { GetTripsParams } from './types'
import {
  getInitialTripsState,
  getInitialTripDetailsState,
  getInitialInsuranceLogsModuleState,
} from './state'
import {
  handleCommonModuleState,
  handleListModuleState,
} from '@/helpers/store-state'
import { getInitialTripLogsModuleState } from '.'
import { showToast } from '@/helpers/messages'
import { InsuranceLog } from '@/models/insuranceLog'

export const useTripsStore = defineStore('trips-store', {
  state: getInitialTripsState,
  actions: {
    async getTrips(params: PaginationParams & GetTripsParams) {
      try {
        this.$patch({ listModule: { isLoading: true, error: null } })

        const {
          data: { data: list, meta },
        } = await tripsApi.getTrips(params)

        this.$patch({
          listModule: {
            list,
            meta,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      } catch (e) {
        this.$patch({
          listModule: {
            error: parseErrors(e).error,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      }
    },
    async getTripDetails(id: string) {
      try {
        const tripsDetails = this.tripsDetails

        if (!tripsDetails[id]) tripsDetails[id] = getInitialTripDetailsState()

        this.$patch({
          tripsDetails: { [id]: { isLoading: true, error: null } },
        })

        const responses = await Promise.all([
          tripsApi.getTripDetails(id),
          tripsApi.getTripCheckIns(id),
          tripsApi.getTripCheckOuts(id),
          reviewsApi.getReviews({ page: 1, perPage: 2, trip_id: id }),
        ])

        const [tripRes, checkInsRes, checkOutsRes, reviewsRes] = responses 
        console.log("🚀 ~ getTripDetails ~ tripRes:", tripRes.data.data)

        const tripWithRelations: TripFullWithRelations = {
          ...tripRes.data.data,
          checkIns: checkInsRes.data.data,
          checkOuts: checkOutsRes.data.data,
          reviews: reviewsRes.data.data,
        }

        this.$patch({
          tripsDetails: { [id]: { isLoading: false, data: tripWithRelations } },
        })
      } catch (e) {
        const { error } = parseErrors(e)
        this.$patch({
          tripsDetails: { [id]: { isLoading: false, error } },
        })
      }
    },

    async updateTripExta(id: string, extra: Partial<TripExtra>) {
      try {
        const {
          data: { data: updatedExtra },
        } = await tripsApi.updateTripExtra(id, extra)

        this.$patch({
          tripsDetails: {
            [id]: {
              isLoading: false,
              data: {
                ...(this.tripsDetails[id] ?? {}),
                extra: updatedExtra,
              },
            },
          },
        })

        showToast({
          message: 'Odometer data updated!',
          type: 'success',
        })
      } catch (e) {
        const { error } = parseErrors(e)
        showToast({
          type: 'error',
          message: error,
        })
      }
    },

    async updateTripData(id: string, payload: Partial<TripFull>) {
      try {
        const {
          data: { data: tripDetails },
        } = await tripsApi.updateTripData(id, payload)

        this.$patch({
          tripsDetails: {
            [id]: {
              isLoading: false,
              success: true,
              data: {
                ...(this.tripsDetails[id] ?? {}),
                ...tripDetails,
              },
            },
          },
        })

        showToast({
          message: 'Status updated!',
          type: 'success',
        })
      } catch (e) {
        const { error } = parseErrors(e)
        showToast({
          type: 'error',
          message: error,
        })
      }
    },

    async finishPayment(id: string, data: FinishTripPaymentPayload) {
      try {
        await tripsApi.finishPayment(id, data)

        showToast({
          message: 'Trip payment finished!',
          type: 'success',
        })
        return true
      } catch (e) {
        const { error } = parseErrors(e)
        showToast({
          type: 'error',
          message: error,
        })
        return false
      }
    },

    async createCancellationRequestComment(
      tripId: string,
      reqId: string,
      comment: string
    ) {
      const tripModule = this.tripsDetails[tripId]
      const commentUpdt = tripModule?.cancellationRequestsModule.commentUpdating

      try {
        if (!commentUpdt || !tripModule?.data) {
          throw new Error('Trip data must be received before request updating.')
        }

        commentUpdt.error = null
        if (!commentUpdt.loadingList.includes(reqId)) {
          commentUpdt.loadingList.push(reqId)
        }
        const successIndex = commentUpdt.successList.indexOf(reqId)
        if (successIndex !== -1) {
          commentUpdt.successList.splice(successIndex, 1)
        }

        const {
          data: { data },
        } = await tripsApi.createCancellationRequestComment(reqId, comment)

        if (!commentUpdt.successList.includes(reqId)) {
          commentUpdt.successList.push(reqId)
        }

        const inList = tripModule.data.cancellation_requests.find(
          req => req.id === reqId
        )
        if (inList) {
          inList.status = data.status
          inList.admin_comment = data.admin_comment
        }

        ElNotification({
          type: 'success',
          duration: 6000,
          message: `Comment was successfully updated.`,
        })
      } catch (e) {
        const { error, validationErrorsRaw } = parseErrors(e)

        if (commentUpdt) commentUpdt.error = error

        const message = `An error while updating comment: "${validationErrorsRaw?.[0]
          .message || error}"`

        ElNotification({ message, type: 'error', duration: 10000 })
      } finally {
        if (commentUpdt) {
          const loadingIndex = commentUpdt.loadingList.indexOf(reqId)
          if (loadingIndex != -1) {
            commentUpdt.loadingList.splice(loadingIndex, 1)
          }
        }
      }
    },

    async resolveCancellationRequest(tripId: string, reqId: string) {
      const tripModule = this.tripsDetails[tripId]
      const resolving = tripModule?.cancellationRequestsModule.resolving

      try {
        if (!resolving || !tripModule?.data) {
          throw new Error(
            'Trip data must be received before request resolving.'
          )
        }

        resolving.error = null
        if (!resolving.loadingList.includes(reqId)) {
          resolving.loadingList.push(reqId)
        }
        const successIndex = resolving.successList.indexOf(reqId)
        if (successIndex !== -1) {
          resolving.successList.splice(successIndex, 1)
        }

        const {
          data: { data },
        } = await tripsApi.resolveCancellationRequest(reqId)

        if (!resolving.successList.includes(reqId)) {
          resolving.successList.push(reqId)
        }

        // Update trip details
        tripModule.data = { ...tripModule.data, ...data.trip }

        // Update trip in list
        const tripI = this.listModule.list.findIndex(({ id }) => id === tripId)
        if (tripI !== -1) {
          this.listModule.list[tripI] = {
            ...this.listModule.list[tripI],
            ...data.trip,
          }
        }

        // Update cancellation request
        const reqInList = tripModule.data.cancellation_requests.find(
          req => req.id === reqId
        )
        if (reqInList) reqInList.status = data.status

        ElNotification({
          type: 'success',
          duration: 6000,
          message: 'Request was successfully resolved.',
        })
      } catch (e) {
        const { error, validationErrorsRaw } = parseErrors(e)

        if (resolving) resolving.error = error

        const message = `An error while updating comment: "${validationErrorsRaw?.[0]
          .message || error}"`

        ElNotification({ message, type: 'error', duration: 10000 })
      } finally {
        if (resolving) {
          const loadingIndex = resolving.loadingList.indexOf(reqId)
          if (loadingIndex != -1) {
            resolving.loadingList.splice(loadingIndex, 1)
          }
        }
      }
    },

    async updateDamageReportStatus(
      tripId: string,
      reportId: string,
      status: DamageReportStatus
    ) {
      const tripModule = this.tripsDetails[tripId]
      const updating = tripModule?.damageReportsModule.updating

      try {
        if (!tripModule?.data || !updating) {
          throw new Error(
            'Trip data must be received before damage report updating.'
          )
        }

        updating.error = null
        if (!updating.loadingList.includes(reportId)) {
          updating.loadingList.push(reportId)
        }
        const successIndex = updating.successList.indexOf(reportId)
        if (successIndex !== -1) {
          updating.successList.splice(successIndex, 1)
        }

        const {
          data: { data },
        } = await tripsApi.updateDamageReportStatus(tripId, reportId, status)

        console.log('Data', data)

        if (!updating.successList.includes(reportId)) {
          updating.successList.push(reportId)
        }

        const inList = tripModule.data.damage_reports.find(
          ({ id }) => id === reportId
        )

        if (inList) inList.status = data.status

        ElNotification({
          type: 'success',
          duration: 6000,
          message: 'Report was successfully updated.',
        })
      } catch (e) {
        const { error, validationErrorsRaw } = parseErrors(e)

        if (updating) updating.error = error

        const message = `An error while updating report: "${validationErrorsRaw?.[0]
          .message || error}"`

        ElNotification({ message, type: 'error', duration: 10000 })
      } finally {
        if (updating) {
          const loadingIndex = updating.loadingList.indexOf(reportId)
          if (loadingIndex != -1) {
            updating.loadingList.splice(loadingIndex, 1)
          }
        }
      }
    },
    async getTripLogs(id: string) {
      try {
        if (!this.tripsLogs[id]) {
          this.tripsLogs[id] = getInitialTripLogsModuleState()
        }

        this.$patch(state => {
          const listModule = state.tripsLogs[id]?.listModule
          if (listModule) handleListModuleState('start', listModule)
        })

        const logs = (await tripsApi.getTripLogs(id)).data.data

        this.$patch(state => {
          const listModule = state.tripsLogs[id]?.listModule
          if (listModule) handleListModuleState('success', listModule, logs)
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const listModule = state.tripsLogs[id]?.listModule
          if (listModule) handleListModuleState('error', listModule, error)
        })
      }
    },
    async createTripLog(tripId: string, comment: string) {
      try {
        if (!this.tripsLogs[tripId]) {
          this.tripsLogs[tripId] = getInitialTripLogsModuleState()
        }

        this.$patch(state => {
          const creation = state.tripsLogs[tripId]?.creation
          if (creation) handleCommonModuleState('start', creation)
        })

        const tripLog = (await tripsApi.createTripLog(tripId, comment)).data
          .data

        this.$patch(state => {
          const logModule = state.tripsLogs[tripId]

          if (!logModule) return

          handleCommonModuleState('success', logModule.creation)
          logModule.listModule.list.unshift(tripLog)
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const creation = state.tripsLogs[tripId]?.creation
          if (creation) handleCommonModuleState('error', creation, error)
        })

        showToast({ type: 'error', message: error })
      }
    },

    async getInsuranceLogs(id: string) {
      let logs: InsuranceLog[] = []
      try {
        if (!this.insuranceLogs[id]) {
          this.insuranceLogs[id] = getInitialInsuranceLogsModuleState()
        }

        this.$patch(state => {
          const listModule = state.insuranceLogs[id]?.listModule
          if (listModule) handleListModuleState('start', listModule)
        })
        const tripId = this.$state.tripsDetails[id]?.data?.id

        if (tripId) {
          logs = (await tripsApi.getInsuranceLogs(tripId)).data.data
        }

        this.$patch(state => {
          const listModule = state.insuranceLogs[id]?.listModule
          if (listModule) handleListModuleState('success', listModule, logs)
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const listModule = state.insuranceLogs[id]?.listModule
          if (listModule) handleListModuleState('error', listModule, error)
        })
      }
    },

    async createInsuranceLog(tripId: string, comment: string) {
      try {
        if (!this.insuranceLogs[tripId]) {
          this.insuranceLogs[tripId] = getInitialInsuranceLogsModuleState()
        }

        this.$patch(state => {
          const creation = state.insuranceLogs[tripId]?.creation
          if (creation) handleCommonModuleState('start', creation)
        })

        const insuranceLogs = (
          await tripsApi.createInsuranceLog(tripId, comment)
        ).data.data

        this.$patch(state => {
          const logModule = state.insuranceLogs[tripId]

          if (!logModule) return

          handleCommonModuleState('success', logModule.creation)
          logModule.listModule.list.unshift(insuranceLogs)
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const creation = state.tripsLogs[tripId]?.creation
          if (creation) handleCommonModuleState('error', creation, error)
        })

        showToast({ type: 'error', message: error })
      }
    },
    async deletePreviewFromTripsDetails(tripId: string, reviewId: string) {
      const tripsDetails = this.tripsDetails[tripId]?.data ?? {}
      const reviews = this.tripsDetails[tripId]?.data?.reviews ?? []

      const tripWithRelations: Partial<TripFullWithRelations> = {
        ...tripsDetails,
        reviews: reviews.filter(({ id }) => id !== reviewId),
      }

      this.$patch({
        tripsDetails: {
          [tripId]: { isLoading: false, data: tripWithRelations },
        },
      })
    },
  },
})
